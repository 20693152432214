$primaryColor: #f4762a;

.labelHeader {
    margin-left: -12px;
}

.closeMapsPanel {
    font-weight: bold;
    float: right;
    cursor: pointer;
}

.labelCountLocation {
    display: block;
    margin-top: 6px;
    color: rgb(150, 150, 150);
}

.coverLocation {
    margin-top: 18px;
}

.mapContainer {
    width: 100%;
    height: 400px;
}

.formControl > label[data-shrink="true"] {
    color: black !important;
}

.formControl > label[data-shrink="true"] ~ * fieldset {
    border-color: rgba(0, 0, 0, .3) !important;
}

.formControl > label[data-shrink="true"] ~ * fieldset legend {
    width: auto !important;
    font-size: 10.5px;
}

.formAutocomplete label[data-shrink="true"] {
    color: black !important;
}

.formAutocomplete label[data-shrink="true"] ~ * fieldset {
    border-color: rgba(0, 0, 0, .3) !important;
}

.formAutocomplete label[data-shrink="true"] ~ * fieldset legend {
    width: auto !important;
    font-size: 10.5px;
}

.textField fieldset {
    border-color: $primaryColor !important;
    border-width: 2px !important;
    margin-top: 5px !important;
}

.textField fieldset legend {
    display: none;
}

.listPlacesBorder {
    width: 100%;
    padding: 3px 3px 0px 3px;
    box-sizing: border-box;
    background: rgb(220, 220, 220);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.listPlacesContainer {
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    background: rgb(240, 240, 240);
    padding: 2px 6px;
    box-sizing: border-box;
}

.locationItem {
    width: 100%;
    background: white;
    height: 50px;
    display: flex;
    align-items: center;

    box-sizing: border-box;
    padding: 0 10px;
    margin-top: 6px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.locationItem span {
    float: left;
}

.locationItem .placeDistance fieldset {
    display: none;
}

.locationItem .rightOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    float: right;
    padding-left: 8px;
    cursor: pointer;
}


.locationItem .labelSelect {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 111.86px);
}

.buttonSection {
    float: right;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}

.buttonSection .resetButton {
    border-radius: 6px;
    background: white;
    border: 0;
    padding: 6px 12px;
    cursor: pointer;
    transition: .2s;
}

.buttonSection .confirmButton {
    border-radius: 6px;
    background: $primaryColor;
    color: white;
    border: 0;
    padding: 6px 12px;
    cursor: pointer;
    margin-left: 24px;
    transition: .2s;
}

.buttonSection .resetButton:hover {
    background:rgba(0, 0, 0, .1);
}

.buttonSection .confirmButton:hover {
    background: rgba($primaryColor, .8);
}