$primaryColor: #f4762a;
$secondaryColor: rgb(181, 180, 180);
;
$greenColor: rgb(108, 172, 25);

@import '../../responsive.scss';

.recommendheader {
  // font-size: 200px;
  // color: red;
  vertical-align: bottom;
  // border-bottom: 1px solid rgb(117, 117, 117);
  border-bottom: 1px solid #c8c8c8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: large; //x-large;
  }

  .seeall {
    text-align: right;
    display: table-cell;
    vertical-align: bottom;
    font-size: small;
  }

}

.cardmeta {
  color: $primaryColor !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
}

.location {
  max-height: 43px;
  overflow: hidden;
}

.priceContainer {
  //   position: absolute;
  //   top: 0;
  //   // background: #0000004f;
  //   // background: linear-gradient(#00000098,#00000050,  #ffffff00);
  //   background: linear-gradient(#00000080,#f4772a00);
  //   width: 100%;
  //   right: 0px;
  //   height: auto;
  //   padding-left: 5px;
  //   padding: 5px;
  height: 58px;
  //   font-weight: bold;
  font-size: 18px;
  display: flex;
}

.projectprice {
  color: $primaryColor;
}

.price {
  color: $primaryColor;
  // color: white;
  // margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
}

.pricerent {
  color: #87d068;
  // color: #fdfffb;
  // margin-top: -10px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
}

.divider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.detail {
  font-size: smaller;
  color: #818181;
  // position: absolute;
  // width: 100%;
  // width: calc(100% - 48px);
  // bottom: 10px;
}

.cardDetailContainer {
  // margin-bottom: 20px;
  height: 125px;
}

.cardfooter {
  font-size: smaller;
  color: $secondaryColor;

  bottom: 0px;
  left: 0px;
  position: absolute;
  // margin-right: 24px;
  // margin-left: 24px;
  // margin-top:0px;
  // margin-bottom: 0px;
  width: 100%;
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.cardstyle {
  height: 450px;

  .normalcard {
    height: 450px;
  }

}

.normalcard {
  // height: 420px;  
}

.projectcard {
  // height: 480px;
}

.unitsection {
  // height: 65px;
  overflow: hidden;
  height: 70px;
}

.cardstyleUnit {
  height: 400px;
}

.unittype {
  color: $greenColor;
  font-size: small;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coverImage {
  height: '180px';
  width: 'auto';
  max-width: '100%';
  margin: 'auto';
  background-image: url('/assets/no_image.jpg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.exclusiveLabel {
  position: absolute;
  top: -1px;
  left: -2px;
  z-index: 1;
  text-align: center;
  background: linear-gradient(87deg, #c50d15 0, #fb6998 100%) !important;
  width: calc(100% + 3px);
  color: white;
}

.cardImagecontainer {
  overflow: hidden;
  height: 200px;
  text-align: center;
  // height: 18vh;
  cursor: pointer !important;
}

.titlecontainer {
  border-bottom: 1px solid #e0e0e0;
  color: #757575;
}

.postUserProjectContainer {
  position: relative;
  width: 100%;
  // left: 0px;
  // padding-left: 24px;
  // padding-right: 24px;
  color: #757575;
  height: 20px;
}

.postUserContainer {
  // position: relative;
  // width: 100%;
  // color: #757575;
  // height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(129, 129, 129);
}

.postuser {
  line-height: 40px;
  // padding-left: 15px;
  font-size: small;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  flex-flow: 1 1 auto;
  width: 135px;
}

.updatedate {
  padding-right: 20px;
  font-size: smaller;
}

.cardImage {
  // height: 200px;
  height: 100%;
  // width: auto;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    // top: 30px;
    left: 0px;
    // width: 100%;
    height: 180px;
    background-image: url(/assets/no-image-bg.webp);
    background-size: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.listcontainer {
  // padding: 10px;
  margin-left: 0px;
  margin-right: 0px;

  // background-color:#dbdbdb;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;

  width: 100%;
}

.cardlocationcontainer {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
}

.nearbyContainer {
  // margin-left: 20px;
  padding-left: 40px;
}

@media only screen and (max-width: 900px) {
  .listcontainer {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  // .cardlocationcontainer {
  //   display: none;
  // }

  .recommendheader {
    padding-left: 0px;
    font-size: larger;
  }


  // .cardImagecontainer {
  //   overflow: hidden;
  //   height: 227px;
  //   text-align: center;
  // }

  // .cardImage {
  //   height: 100%;
  //   width: auto;
  //   margin: auto;
  //   overflow: hidden; 
  // }

  // .priceContainer {
  //     position: absolute;
  //     top: 0;
  //     // background: #0000004f;
  //     // background: linear-gradient(#00000098,#00000050,  #ffffff00);
  //     background: linear-gradient(#000000a8,#00000000);
  //     width: 100%;
  //     right: 0px;
  //     height: auto;
  //     padding-left: 5px;
  //     padding: 5px;
  //     height: 80px;
  //     font-weight: bold;
  // }


  // .price {
  //   color: $primaryColor;
  //   color: white;
  //   margin-top: 8px;
  //   margin-bottom: 8px;
  // }

  // .pricerent {
  //   color: white;
  //   // color: #fdfffb;
  //   margin-top: 10px;
  // }


  .nearbyContainer {
    // margin-left: 20px;
    padding-left: 0px;
    margin-top: 20px;

    .price {
      color: $primaryColor;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

}

.recommendCardContainer {
  font-size: small;
}

.detailIcon {
  width: 20px;
  color: #818181;
  margin-top: 5px;
}

.iconsStyle {
  top: 10px !important;
}

.cardDetailNumber {
  color: #f4762a;
  padding-right: 4px;
  font-weight: bold;
  font-size: 14px;
}

.category {
  position: absolute;
  margin-top: -23px;
}

.cardItemContainer {
  display: flex;
  flex-flow: wrap;
  gap: 10px;
  // flex-grow: 1;
  // background-color: red;

  .cardItem {
    width: 280px;
    @include breakpoint(sm, 0) {
      flex-grow: 1;
    }
  }
}


.cardContainerOutter {
  height: 500px;
  // display: flex;
  // flex-direction: column; 
  // align-content: space-between;
  position: relative;
  .cardHeader {
    position: relative;
    top: 0px;  
  }
  
  .cardbody {
    padding: 5px;
    .cardTitle {
      // background-color: red;
      height: 30px;
      color: $primaryColor;
      font-size: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    // background-color: red;
  }
  
  .detail {
    // background-color: blue;
    position: relative;
    top: 20px;
  }

  .cardActions {
    // background-color: yellow;
    bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    border-top: solid 1px rgb(231, 231, 231);
      :nth-child(2):before, :nth-child(3):before, :nth-child(4):before {
        // border-left: solid 1px #cacaca;
        content: ' ';
        display: block;
        background:  rgb(231, 231, 231);
        width: 1px;
        height: 30px;
        left: -15px;
        position: absolute;
      }

      // :nth-child(1):after {
      //   content: " ";
      //   display: block;
      //   background: #c00;
      //   height: 29px;
      //   width: 100%;
      // }

      // :nth-child(1) {
      //   background-color: red;        
      // }
  }
  .postuser {
    // background-color: red;
    // position: relative;
    // bottom: 0px;
    // width: 100%;
  }
  
  .projectprice {
    color: $primaryColor;
    font-weight: bold;
  }
}
