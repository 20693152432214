.gallery {
    height: 500;
    width: '800px';
    margin: auto;
}


.header {
    font-size: large;
}

.facilityContainer {
    display: flex;
    justify-items: center;
    align-items: center;
}

.facilitieIcon {
    background-image: url('/assets/checked.png') !important;
    width: 30px;
    height: 16px;
    display: block;
    // background: red;
    background-repeat: no-repeat;
    // margin-right: 10px;
    padding-right: 10px;
}

.facilitieText {
    text-align: left;
}

.pricePerMeter {
    padding-left: 20px;
}

.owerInformation {
    padding: 20px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 10px;
}

.textLimit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableRow {
    padding: 4px;

    &:nth-child(odd) {
        background-color: #f0f0f0;
    }
}

@media only screen and (max-width: 900px) {
    .gallery {
        height: auto;
        width: auto;
        margin: auto;
    }

    .titleInformation {
        font-size: 14px;
        color: rgb(107, 107, 107)
    }

    .information {
        font-size: 16px;
        font-weight: bold;
    }

    .tableRow {
        padding: 4px 0;

        &:nth-child(odd) {
            background-color: transparent;
        }
    }
}