.container {
    display: flex;
    flex-direction: row;
    // overflow: scroll;
    padding-top: 18px;
}

.categoryimage {
    justify-content: center;
}

.categoryname {
    font-size: 10px;
    justify-content: center;
    text-align: center;
}

.rightarrow {
    width: 50px;
    margin-right: -20px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    // margin-left: 20px;
}

.leftarrow {
    width: 50px;
    margin-right: -20px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    // margin-left: 20px;
}


.contentstyle {
    height: 120px;
    display: flex;
    flex-direction: row;
    // overflow: scroll;
    padding-top: 18px;
}