.titleInformation {
    font-weight: bold;
}

.tableRow {
    padding: 4px;

    &:nth-child(odd) {
        background-color: #f0f0f0;
    }
}

@media only screen and (max-width: 900px) {
    .gallery {
        height: auto;
        width: auto;
        margin: auto;
    }
}