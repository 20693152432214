.container {
    display: flex;
    flex-direction: row;
    // overflow: scroll;
    padding-top: 18px;
}

.imageItemsContainer {
    // height: 100%;
    // width: auto;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    // scroll-behavior: smooth;
    margin: auto;
}

.imageItemStyle {
    height: 400px;
    padding-left: 2px;
    padding-right: 2px;
}

.leftArrow {
    width: 50px;
    // background-color: #ff00001c;
    position: absolute;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #ff00001c;
        .detailIcon {
            color: rgb(255, 255, 255);
        }  
    }
}


.rightArrow {
    width: 50px;
    // background-color: #ff00001c;
    position: absolute;
    height: 400px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #ff00001c;   
        .detailIcon {
            color: rgb(255, 255, 255);
        } 
    }
}


.detailIcon {
    color: rgba(255, 255, 255, 0.596);
    &:hover {
        color: rgb(255, 255, 255);
    }
}