.owerInformation {
    padding: 20px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 10px;
}

.titlePrice {
    padding: 4px 8px;
}

.titlePrice-pricePerMeter {
    display: flex;
    flex-wrap: wrap;
}

.facilitieIcon {
    background-image: url('/assets/checked.png') !important;
    width: 30px;
    height: 16px;
    display: block;
    // background: red;
    background-repeat: no-repeat;
    // margin-right: 10px;
    padding-right: 10px;
}

@media (min-width: 768px) {
    .col-title {
        max-width: fit-content;
    }

    .pricePerMeter {
        padding-left: 20px;
    }

    .titlePrice-pricePerMeter {
        display: flex;
    }
}