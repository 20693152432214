.linkHitsContainer {
    // background-color: red;
    padding-top: 10px;
    padding-bottom: 40px;
    color: rgb(109, 109, 109);
    // max-width: 1200px;
    margin: auto;
    font-size: small;
    height: 300px;
}

@media (min-width: 900px) {
    .linkHitsContainer {
        // padding-left: 20px;
        // padding-right: 20px;
    }
}

.linkHitsHeader {
    font-size: medium;
    font-weight: bold;
}

.linkHitsItem {
    color:#23527c;
}

// Mobile
@media only screen and (max-width: 900px) {
    .linkHitsContainer {
        // background-color: red;
        // padding-top: 40px;
        padding: 10px;
        // padding-bottom: 40px;
        color: rgb(109, 109, 109);
        // max-width: 1200px;
        margin: auto;
        height: auto;
    }
}