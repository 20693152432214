
$primaryColor: #f4762a;
$secondaryColor: rgb(181, 180, 180);;
$greenColor: rgb(108, 172, 25);


.cardmeta {
  color: $primaryColor !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price {
  color: $primaryColor;
  // color: white;
  // margin-bottom: 5px;
  margin-left: 5px;
}
.pricerent {
  color: #87d068;
  // color: #fdfffb;
  margin-top: -10px;
  margin-bottom: 8px;
}

.cardImage {
  height: 100px;
  // height: auto;
  width: 100%;
  // max-width: 100%;
  margin: auto;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    // top: 30px;
    left: 0px;
    // width: 100%;
    height: 80px;    
    background-image: url(/assets/no-image-bg.webp);
    background-size: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.nearbyContainer {
  // margin-left: 20px;
  // padding-left: 40px;
  margin-top: 15px;
}

.recommendCardContainer {
  font-size: small;
}

.headerIcon {
  margin-right: 10px;
}
