.container {
    // background-color: red;
    padding-top: 40px;
    padding-bottom: 10px;
    color: rgb(109, 109, 109);
    font-size: small;
}


@media (min-width: 900px) {
    .container {
        // padding-left: 10px;
        // padding-right: 10px;
    }
}

.header {
    font-size: large;
    font-weight: bold; 
}

.itemHeader {
    font-size: medium;
    font-weight: bold; 
    padding-bottom: 5px;
    padding-top: 5px;
}

.item {
    padding-right: 10px;
    color:#23527c;
}