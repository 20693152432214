$primaryColor: #f4762a;
$secondaryColor: rgb(181, 180, 180);
$greenColor: rgb(108, 172, 25);

.fullCardContainer {
  max-height: none !important;
}

.cardContainer {
  box-shadow: 0 0 3px rgba(0, 0, 0, .2) !important;
  width: 100%;
}

.title {
  background-color: $primaryColor;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  text-align: center;
  padding: 4px;
  color: white;
  font-weight: bold;
}

.formContainer {
  top: 35px;
}

.buttonContainer {
  text-align: center;
}

.formControl > label[data-shrink='true'] {
  color: black !important;
}

.formControl > label[data-shrink='true'] ~ * fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.formControl > div > div[aria-expanded='true'] ~ fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.formControl > label[data-shrink='true'] ~ * fieldset legend {
  width: auto !important;
  font-size: 10.5px;
}

.formControl textarea:focus ~ fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.dropdownControl fieldset {
  border-color: rgba(0, 0, 0, 0.2) !important;
  border-width: 1px !important;
  margin-top: 5px !important;
}

.dropdownControl fieldset legend {
  display: none;
}

.formAutocomplete label[data-shrink='true'] {
  color: black;
}

.formAutocomplete label[data-shrink='true'] ~ * fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.formAutocomplete div > input[aria-expanded='true'] ~ fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.formAutocomplete label[data-shrink='true'] ~ * fieldset legend {
  width: auto !important;
  font-size: 10.5px;
}

.viewMore {
  width: 100%;
  height: 60px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  font-size: 1.1rem;
  font-weight: 700;
  display: none;
}

.viewMore span,
.viewMore img {
  margin-bottom: 5px;
}

.viewMore img {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.hideViewMore {
  display: none !important;
}

.locationContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1202;

  display: flex;
  align-items: center;
  justify-content: center;
}

.locationContainer .hoverBackground {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.mapsContainer {
  position: absolute;
  width: 624px;
  background: white;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  padding: 24px;
  padding-top: 14px;
}

.mapsContainer > * {
  outline: none;
}

.modalSuccess2 .ant-modal-content {
  padding: 10px 12px;
}

.modalSuccess {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100% !important;
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  z-index: 100000;

  display: flex;
  align-items: center;
  justify-content: center;
}

.successCard {
  width: 100%;
  background: white;
  padding: 24px 12px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: rgb(50, 50, 50);
}

.successCard .txtYour {
  font-size: 1.4em;
}

.successCard .txtRequirement {
  font-size: 2em;
  color: $greenColor;
}

.successCard .txtRecorded {
  font-size: 1.3em;
}

.successCard .txtRequirementId {
  text-align: center;
  font-size: 1.4em;
  margin-top: 46px;
}

.successCard .txtRequirementId span {
  color: $primaryColor;
}

.successCard .txtDetail {
  font-size: 1em;
  text-align: center;
  margin: 46px 0;
}

.successCard .txtDetail2 {
  font-size: 0.9em;
  text-align: center;
  margin: 10px 0 30px 0;
}

.successCard .btnFinish {
  font-size: 1em;
  background: $primaryColor;
  border-radius: 6px;
  padding: 6px 24px;
  color: white;
  border: 0;
  outline: none;
  cursor: pointer;
  margin: 2px 5px;
}

.budgetGroup {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.formBudgetInput {
  width: 70%;
  box-sizing: border-box;
}

.formCurrencyInput, .formCurrencyInputDisabled {
  width: 30%;
  box-sizing: border-box;
}

.formCurrencyInputDisabled > div > div {
  padding-right: 0 !important;
}

.budgetInput {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.currencyInput {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

@media only screen and (max-width: 900px) {
  .title {
    position: fixed;
    z-index: 2;
  }

  .viewMore {
    display: flex;
  }

  .cardContainer {
    // max-height: 440px;
    // overflow: hidden;
    display: block;
    margin-bottom: 24px;
  }

  .mapsContainer {
    width: 100%;
  }

  .formProjectName {
    display: none;
  }

  .formLocation {
    display: none;
  }

  .formBuyfor {
    display: none;
  }

  .successCard {
    width: 90%;
    background: white;
    padding: 24px 12px;
    box-sizing: border-box;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    color: rgb(50, 50, 50);
  }
}

@media only screen and (max-width: 600px) {
  .cardContainer {
    margin-bottom: 0px;
  }
}

.formControl > label ~ * input[type='number'] {
  -moz-appearance:textfield;
}

.formControl > div > div ~ input[type='number'] {
  -moz-appearance:textfield;
}

.formControl > label ~ * input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formControl > div > div ~ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formControl > label ~ * input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formControl > div > div ~ input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formControl * {
  // font-size: 12px !important;
}

.dropdownControl * {
  // font-size: 12px !important;
}

.formAutocomplete * {
  // font-size: 12px !important;
}